import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./styles/sah.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../src/js/custom";
import "sanitize.css/sanitize.css";
import "popper.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "video-react/dist/video-react.css";
import * as React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import history from "./utils/history";
import App from "./containers/App";
import LanguageProvider from "./containers/LanguageProvider";
import { translationMessages } from "./i18n";
import configureStore from "./configureStore";
import Modal from "react-modal";



//TODO: https://github.com/bvaughn/react-window
//TODO: https://malte-wessel.com/react-textfit/


const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("root");
Modal.setAppElement("#root");

const defRender = (messages, Component = App) => {
    // if(window !== undefined && window.adsbygoogle !== undefined){
    //   let adsbygoogle;
    //     (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1;
    //     (adsbygoogle=window.adsbygoogle||[]).push({google_ad_client: "ca-pub-9292890835737233", enable_page_level_ads: true});
    // }
  const actionFunction = MOUNT_NODE.hasChildNodes() ? hydrate : render;
  actionFunction(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  );
};

// Chunked polyfill for browsers without Intl support
if (!(window).Intl) {
  new Promise((resolve) => {
    resolve(import("intl"));
  })
    // .then(() => Promise.all([import('./intl/locale-data/jsonp/en.js'), import('./intl/locale-data/jsonp/de.js')]))
    .then(() => defRender(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  defRender(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
//if (process.env.NODE_ENV === 'production') {
//    require('offline-plugin/runtime').install();
//}
